import styled from 'styled-components';

type SectionProps = {
  largePadding?: boolean;
};

const Section = styled.section<SectionProps>`
  padding: 3.2rem 1.6rem;

  ${({ theme }) => theme.MEDIA.SM} {
    padding: 4.8rem 1.6rem;
  }

  ${({ theme }) => theme.MEDIA.LG} {
    padding: ${({ largePadding }) => (largePadding ? '12rem 1.6rem' : '8rem 1.6rem')};
  }
`;

export default Section;
