import styled from 'styled-components';

import Trans from 'next-translate/Trans';
import Image from 'next/image';
import Section from 'shared/components/Section';
import useTranslation from 'shared/hooks/useTranslation';

import ContentSwitch from './ContentSwitch';

const StyledSection = styled(Section)`
  color: black;
  padding-left: 0;
  padding-right: 0;
  max-width: 100vw;
  overflow-x: hidden;

  @media (min-width: 1170px) {
    align-content: center;
    display: grid;
    grid-template-columns: 1fr 60rem;
    justify-content: center;
    padding: 4rem 1.6rem;
    min-height: 100vh;
    min-height: 100vh;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 8rem;
    padding-bottom: 18rem;
    padding-left: 8rem;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  min-height: 12rem;

  @media (min-width: 1171px) {
    display: none !important;
  }
`;

const StyledPText = styled.p`
  color: black;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 110%;
  margin: 0 auto;
  max-width: 90%;
  text-align: center;

  span {
    color ${({ theme }) => theme.COLORS.V2.SECONDARY};
  }
`;

const ListWrapper = styled.div`
  font-size: 16px;
  padding: 0 24px;
  width: fit-content;
  margin: 0 auto;

  @media (max-width: 1170px) {
    display: none !important;
  }

  @media (max-height: 650px) {
    font-size: 15px;
  }

  ${({ theme }) => theme.MEDIA.SM} {
    font-size: 18px;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    display: block;
    padding: 12px 0 0 24px;
  }

  @media (min-width: 1440px) {
    padding: 12px 0 0 6px;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 32px;
  margin-top: 2rem;

  @media (max-height: 650px) {
    font-size: 21px;
  }

  ${({ theme }) => theme.MEDIA.SM} {
    font-size: 36px;
    line-height: 1.25;
    margin-bottom: 40px;
  }
`;

const StyledUl = styled.ul`
  font-family: ${({ theme }) => theme.FONTS.SECONDARY};
  list-style: none;
  margin-bottom: 40px;
  padding-left: 0;

  ${({ theme }) => theme.MEDIA.XL} {
    margin-bottom: 50px;
  }

  li {
    margin-bottom: 16px;
    padding-left: 40px;
    position: relative;

    &:before {
      background-image: url('/img/shared/tm-star-v2.svg');
      background-size: contain;
      content: '';
      height: 27px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 27px;
    }
  }
`;

const StyledDiv = styled.div`
  font-family: ${({ theme }) => theme.FONTS.SECONDARY};
  margin-bottom: 16px;

  ${({ theme }) => theme.MEDIA.XL} {
    margin-bottom: 38px;
  }
`;

function MainSectionVideoMobile(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <StyledSection>
      <ListWrapper>
        <Header>
          {t('mainSection.enVersion.header1')} <br />
          {t('mainSection.enVersion.header2')}
        </Header>
        <StyledDiv>{t('mainSection.enVersion.listTitle')}:</StyledDiv>
        <StyledUl>
          <li>{t('mainSection.enVersion.listItem1')}</li>
          <li>{t('mainSection.enVersion.listItem2')}</li>
          <li>{t('mainSection.enVersion.listItem3')}</li>
        </StyledUl>
      </ListWrapper>
      <div>
        <TextWrapper>
          <ContentSwitch>
            <StyledPText>
              <Trans
                i18nKey="homePage:mainSection.unique"
                components={[<br key="enter" />]}
                fallback="homePageEN:mainSection.unique"
              />
            </StyledPText>
            <StyledPText>
              <Trans
                i18nKey="homePage:mainSection.longReviews"
                components={[<br key="enter" />, <span key="key word">słowa kluczowe</span>]}
                fallback="homePageEN:mainSection.longReviews"
              />
            </StyledPText>
            <StyledPText>
              <span>
                <Trans
                  i18nKey="homePage:mainSection.increase"
                  components={[<br key="enter" />]}
                  fallback="homePageEN:mainSection.increase"
                />
              </span>
            </StyledPText>
          </ContentSwitch>
        </TextWrapper>
        <ImageWrapper>
          <Image src="/img/home/v2/main/mobile-phone.png" alt="" height={513} width={500} priority />
        </ImageWrapper>
      </div>
    </StyledSection>
  );
}

export default MainSectionVideoMobile;
