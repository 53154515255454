import React, { ReactNode, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface ScrollAnimationProps {
  children: ReactNode;
}

interface StyledContentSwitchProps {
  visibleIndexElement: number;
}

const opacity = keyframes`
  {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1
    }
    80% {
      opacity: 1
    }
    100% {
      opacity: 0;
    }
  }
`;

const StyledContentSwitch = styled.div<StyledContentSwitchProps>`
  & > * {
    display: none;
    opacity: 0;
  }
  & > *:nth-child(${({ visibleIndexElement }) => visibleIndexElement}) {
    display: block;
    animation: ${opacity} 2.5s ease-in-out infinite;
  }
`;

function ContentSwitch({ children }: ScrollAnimationProps): JSX.Element {
  const [visibleIndexElement, setVisibleIndexElement] = useState(1);
  const maxIndex = React.Children.count(children);
  let timer: number | boolean | NodeJS.Timeout | undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCount = () => {
    timer =
      !timer &&
      setInterval(() => {
        if (maxIndex > visibleIndexElement) {
          setVisibleIndexElement((prevCount) => prevCount + 1);
        } else {
          setVisibleIndexElement(1);
        }
      }, 2500);
  };

  useEffect(() => {
    updateCount();

    return () => clearInterval(timer as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleIndexElement]);

  return <StyledContentSwitch visibleIndexElement={visibleIndexElement}>{children}</StyledContentSwitch>;
}

export default ContentSwitch;
